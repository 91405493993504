<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
      <ion-title>{{ pageTitle }}</ion-title>
      <ion-icon v-if="this.isNative" slot="end" v-on:click="shareButton" :icon="shareSocialSharp"></ion-icon>
    </ion-toolbar>
  </ion-header>
</template>

<script>

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonIcon,
} from '@ionic/vue';
import {Share} from "@capacitor/share";
import {shareSocialSharp} from "ionicons/icons";
import {Capacitor} from "@capacitor/core";

export default {
  props: ['pageTitle'],
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonIcon,
  },
  setup() {
    return {
      shareSocialSharp,
    }
  },
  data() {
    return{
      isNative: Capacitor.isNativePlatform()
    }
  },
  methods: {
    shareButton() {
      Share.share({
        title: 'Distractie Online',
        text: 'Iti recomand aplicatia Distractie Online! O Poti descarca gratuit din Google Play: https://play.google.com/store/apps/details?id=com.distractieonline.app',
        url: 'https://play.google.com/store/apps/details?id=com.distractieonline.app',
        dialogTitle: 'Distribuie Distractie Online',
      })
    }
  }
};
</script>

<style scoped>
ion-header ion-toolbar {
  --background: #3880ff;
  color: white;
}

ion-header ion-toolbar ion-icon {
  width: 25px;
  height: 25px;
  padding-right: 10px;
}
</style>