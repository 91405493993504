<template>
  <ion-page>
    <top-header page-title="Disțractie Online"></top-header>
    <ion-content :fullscreen="true">
      <div class="logo-container">
        <ion-img src="assets/img/logo.png"></ion-img>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h3>Acum asculți</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h6>Disțractie Online</h6>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button v-on:click="toggleAudio" class="ion-home" shape="round">
              <ion-icon :icon="playButton"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/vue';
import {playSharp, pauseSharp} from 'ionicons/icons';
import TopHeader from "../components/menu/TopHeader";
import {Howl} from 'howler';
import {Capacitor} from "@capacitor/core";
import {MusicControls} from '@ionic-native/music-controls';

//Working media Plugin:
//cordova-plugin-streaming-media @ionic-native/streaming-media But with overlayer media player

//Not working media Plugin:
// @capacitor-community/native-audio
// capacitor-music-controls-plugin
// import {MusicControl} from "capacitor-music-control";

// Maybe
//@ionic-native/media cordova-plugin-media cordova-plugin-file

export default {
  components: {
    IonButton,
    IonContent,
    IonPage,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    TopHeader,
  },
  setup() {
    return {
      playSharp,
      pauseSharp,
    }
  },
  data() {
    return {
      audio: null,
      playButton: this.playSharp,
      musicControlCreated: false
    }
  },
  methods: {
    toggleAudio() {
      // IOS needs the audio to be loaded on user input
      if(!this.audio){
        this.$store.dispatch('loadAudioOnInput', new Howl({
                src: ['https://radio.ascultatare.ro:9520/stream;'],
                ext: ['mp3'],
                html5: true,
              }));
        this.audio = this.$store.getters.loadStream;
      }

      if (this.audio.playing()) {
        this.audio.stop();
        this.playButton = this.playSharp;

        if (Capacitor.isNativePlatform()) {
          MusicControls.updateIsPlaying(false);
        }
      } else {
        this.audio.play();
        this.playButton = this.pauseSharp;

        if (Capacitor.isNativePlatform()) {
          if (!this.musicControlCreated) {
            this.createMusicControls();
            this.musicControlCreated = true;
          }
          MusicControls.updateIsPlaying(true);
        }
      }
    },
    createMusicControls() {
      MusicControls.create({
        track: 'Acum asculti',
        artist: '...',
        cover: '/assets/img/logo.png',
        isPlaying: false,
        hasPrev: false,
        hasNext: false,
      });

      MusicControls.subscribe().subscribe((action) => {
        const message = JSON.parse(action).message;
        switch (message) {
          case 'music-controls-pause':
            this.toggleAudio();
            break;
          case 'music-controls-play':
            this.toggleAudio();
            break;
            // External controls (iOS only)
          case 'music-controls-toggle-play-pause' :
            this.toggleAudio();
            break;
          default:
            break;
        }
      });

      MusicControls.listen();
      MusicControls.updateIsPlaying(false);
    }
  },
  created() {
    this.audio = this.$store.getters.loadStream;
    if (this.audio && this.audio.playing()) {
      this.playButton = this.pauseSharp;
    }
  },
}
</script>

<style scoped>
h3, h6 {
  padding: 0;
  margin: 0;
  color: white;
}

ion-content {
  --background: url(/assets/img/background.jpeg) 0 0/100% 100% no-repeat;
}

ion-content .logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

ion-content .logo-container ion-img {
  width: 150px;
  height: auto;
}

ion-content ion-grid {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}

ion-content ion-grid ion-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ion-content ion-grid ion-row:first-of-type {
  background-image: linear-gradient(transparent, black);
}

ion-content ion-grid ion-row:nth-child(2) {
  background-image: linear-gradient(black, transparent);
}

ion-content ion-grid ion-button {
  height: 75px;
}

ion-content ion-grid ion-button ion-icon {
  font-size: 25px;
  color: white;
}
</style>
